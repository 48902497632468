import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFile } from '../../../api/documents';

import { FILE_MAX_SIZE, FILE_TYPES } from '../../../constants/file-upload';
import { notify } from '../../../utils/notify';
import Button from '../Button/Button';
import './fle-uploader.scss';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import DocumentTypePopup from '../DocumentTypePopup';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import UploadFileIcon from '../../../assets/Svg/UploadFileIcon';
import AddFileIcon from '../../../assets/Svg/AddFileIcon';

const FileUploadComponent = (props) => {
  const { label, isMultiple } = props;
  const intl = useIntl();
  const typeText = intl.formatMessage({
    id: 'fileUploader.text.type',
    defaultMessage:
      'Supported files are .DOC, .DOCX, .PDF, .JPG, .PNG, .ODT. The maximum size of a single file is 16 Mb.',
  });
  const [files, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (fileOrFileList) => {
    if (!fileOrFileList) {
      return;
    }
    const fileArr = [];
    if (isMultiple) {
      for (const key of Object.keys(fileOrFileList)) {
        fileArr.push(fileOrFileList[key]);
      }
    } else {
      fileArr.push(fileOrFileList);
    }
    setProgress(0);
    setFile(fileArr);
  };

  const deleteFile = (pos) => {
    if (!files || !files.length) {
      return setFile(null);
    }
    const arr = files.filter((item, index) => index !== pos);
    if (!arr.length) {
      return clearData();
    }
    setFile(arr);
  };

  const renderFiles = () => {
    const renderedFiles = [];
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        renderedFiles.push(
          <div key={i} className={'file'}>
            <i className={'file__delete'} onClick={deleteFile.bind(undefined, i)}></i>
            <span>{files[i].name}</span>
          </div>,
        );
      }
    }
    return renderedFiles;
  };

  const requestConfig = {
    onUploadProgress: (progressEvent) => {
      const total = progressEvent.total;
      const persent = total / 100;
      const uploaded = progressEvent.loaded / persent;
      setProgress(Number(uploaded.toFixed()));
    },
  };

  const clearData = () => {
    const input = document.querySelector('input[type="file"]');
    const form = document.querySelector('form');
    form.value = '';
    input.value = '';
    setFile(null);
  };

  const sendFile = (data) => {
    if (!data) {
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    const formDataFileKey = isMultiple ? 'file[]' : 'file';

    for (const item of files) {
      formData.append(formDataFileKey, item, item.name.split('.')[0]);
      formData.append('type_key', data?.type?.props?.values);
    }

    for (const item of Object.keys(data)) {
      formData.append(item, item === 'category_type' ? data?.category_type?.props?.values : data?.type?.props?.values);
    }

    uploadFile(formData, requestConfig)
      .then((res) => {
        setProgress(0);
        clearData();
        setIsUploading(false);

        NotificationManager.success(
          intl.formatMessage({ id: 'file.upload.success.text' }),
          intl.formatMessage({ id: 'file.upload.success.title' }),
          3000,
        );
      })
      .catch((err) => {
        setProgress(0);
        setIsUploading(false);

        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  const onSizeError = () => {
    NotificationManager.error(
      intl.formatMessage({ id: 'file.upload.size.error.text'}).replace('{SIZE}', FILE_MAX_SIZE),
      intl.formatMessage({ id: 'file.upload.size.error.title' }),
      3000,
    );
  };

  const onTypeError = () => {
    NotificationManager.error(
      intl.formatMessage({ id: 'file.upload.type.error.text' }).replace('{TYPES}', FILE_TYPES.join(', ')),
      intl.formatMessage({ id: 'file.upload.type.error.title' }),
      3000,
    );
  };

  const filesBlock = (
    <div className={'file-uploader__files'}>
      {progress !== 0 ? (
        <div className={'progress-bar'}>
          <span className={'progress-bar__status'}>{progress}%</span>
          <ProgressBar now={progress} />
        </div>
      ) : (
        ''
      )}
      <DocumentTypePopup
        trigger={
          <Button
            type='button'
            buttonText={<FormattedMessage id='fileUploader.buttonSend' defaultMessage='Send' />}
            size='big'
            disabled={isUploading}
          />
        }
        onClose={sendFile}
        docName={files?.[0].name}
      />
      <div className={'files__container'}>{renderFiles()}</div>
    </div>
  );

  return (
    <>
      <div className={'file-uploader'}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={'file-uploader__item'}>
            <FileUploader
              handleChange={handleChange}
              name='file'
              multiple={isMultiple}
              hoverTitle={' '}
              fileOrFiles={files}
              onSizeError={onSizeError}
              onTypeError={onTypeError}
              classes='drop_area drop_zone'
              maxSize={FILE_MAX_SIZE}
              types={FILE_TYPES}
            >
              <div className={'file-uploader__area'}>
                {/*<i className={'file-uploader__icon'}></i>*/}
                <UploadFileIcon className={'file-uploader__icon'} />
                <span className={'file-uploader__title'}>
                  <FormattedMessage
                    id='fileUploader.title'
                    defaultMessage='Drag & Drop to Upload file'
                  />
                </span>
                {!files ? (
                  <span>
                    <FormattedMessage id='fileUploader.or' defaultMessage='OR' />
                  </span>
                ) : (
                  ''
                )}
                <div className={'file-uploader__bottom'}>
                  <span className={'file-uploader__button'}>
                    <FormattedMessage id='fileUploader.button' defaultMessage='Browse files' />
                    <AddFileIcon />
                  </span>
                </div>
              </div>
            </FileUploader>
          </div>
          {files && files.length ? filesBlock : ''}
        </form>
        {files ? '' : <span className={'file-uploader__types'}>{typeText}</span>}
      </div>
    </>
  );
};

export default FileUploadComponent;
