import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { documentTypes } from '../../../api/documents';
import './document-type.scss';
import Button from '../Button/Button';
import Select from 'react-select';
import { SELECT_DEFAULT_STYLES } from '../../../constants/select';
import PopupTemplate from '../PopupTemplate';
import './select-fix.scss';
import { FormattedMessage } from 'react-intl';
import UploadFileIcon from '../../../assets/Svg/UploadFileIcon';

const DocumentTypePopup = ({ trigger, docName, onClose }) => {
  const [typesData, setTypesData] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedType, setSelectedType] = useState();

  const [defaultCategory, setDefaultCategory] = useState();
  const [defaultType, setDefaultType] = useState();

  const customStyles = {
    ...SELECT_DEFAULT_STYLES,
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: 150,
    }),
  };

  const changeTypesDefaultValue = (value) => {
    const typeOpt = typesData.filter((el) => el.key === value)[0].statuses;
    const el = typeOpt.map((el) => (
        {
          value: translateLanguageType(el.key),
          label: translateLanguageType(el.translate),
          key: el.key,
        }
      ),
    );
    const filterId = el.filter(item => item.key !== 'id_card');
    setTypesOptions(filterId);
    setSelectedType(null);
    setSelectedType(null);
  };

  const closeModal = (closeModal) => {
    setSelectedCategory(null);
    setSelectedType(null);

    setDefaultCategory(null);
    setDefaultType(null);

    closeModal();
    onClose();
  };

  const translateLanguageType = (item) => {
    switch (item) {
      case 'passport':
        return <FormattedMessage
          id='documentTypes.popup.file.type.passport'
          defaultMessage='Passport'
          values='passport'
        />;
      case 'driving_licence':
        return <FormattedMessage
          id='documentTypes.popup.file.type.driving_licence'
          defaultMessage='Driving licence'
          values='driving_licence'
        />;
      case 'utility_bill':
        return <FormattedMessage
          id='documentTypes.popup.file.type.utility_bill'
          defaultMessage='Utility bill'
          values='utility_bill'
        />;
      case 'bank_statement':
        return <FormattedMessage
          id='documentTypes.popup.file.type.bank_statement'
          defaultMessage='Bank statement'
          values='bank_statement'
        />;
      case 'rental_agreement':
        return <FormattedMessage
          id='documentTypes.popup.file.type.rental_agreement'
          defaultMessage='Rental agreement'
          values='rental_agreement'
        />;
      case 'insurance':
        return <FormattedMessage
          id='documentTypes.popup.file.type.insurance'
          defaultMessage='Insurance'
          values='insurance'
        />;
      case 'residence_other':
        return <FormattedMessage
          id='documentTypes.popup.file.type.residence_other'
          defaultMessage='Residence other'
          values='residence_other'
        />;
      // default:
      //   return '';
    }
  };

  const translateLanguageCategory = (item) => {
    switch (item) {
      case 'proof_of_identity':
        return <FormattedMessage
          id='documentTypes.popup.file.category.proof_of_identity'
          defaultMessage='Proof of identity'
          values='proof_of_identity'
        />;
      case 'proof_of_residence':
        return <FormattedMessage
          id='documentTypes.popup.file.category.proof_of_residence'
          defaultMessage='Proof of residence'
          values='proof_of_residence'
        />;
      default:
        return '';
    }
  };

  useEffect(() => {
    documentTypes()
      .then((res) => {
        setTypesData(res.data.data);
        const categoryOptions = res.data.data.map((item) => ({
          value: translateLanguageCategory(item.key),
          label: translateLanguageCategory(item.translate),
          key: item.key,
        }));
        const filterCategory = categoryOptions.filter(item => item.value !== 'proof_of_payment' || item.label !== 'proof_of_payment');

        setCategoryOptions(filterCategory);
        // setTypesData(filterCategory);

      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <FormattedMessage id='documentTypes.popup.title' defaultMessage='Set document type' />
            }
            leftContent={
              <div className={'document-types__descr'}>
                <UploadFileIcon />
              </div>
            }
            rightContent={
              <div className={'document-types__content'}>
                <div className={'document-types__name'}>
                  <span>
                    <FormattedMessage id='documentTypes.popup.file.name' defaultMessage='Name:' />
                  </span>
                  <span>{docName}</span>
                </div>
                <div className={'document-types__categories'}>
                  <div>
                    <span className={'document-types__label'}>
                      <FormattedMessage
                        id='documentTypes.popup.file.category'
                        defaultMessage='Category:'
                      />
                    </span>
                    <Select
                      options={categoryOptions}
                      styles={customStyles}
                      // defaultValue={defaultCategory}
                      placeholder={
                        <FormattedMessage
                          id='documentTypes.popup.file.category.placeholder'
                          defaultMessage='Select category'
                        />
                      }
                      classNamePrefix='type-select'
                      components={{ IndicatorSeparator: () => null }}
                      onChange={(val) => {
                        setSelectedCategory(val);
                        changeTypesDefaultValue(val.key);
                      }}
                    ></Select>
                  </div>
                  <div>
                    <span className={'document-types__label'}>
                      <FormattedMessage id='documentTypes.popup.file.type' defaultMessage='Type:' />
                    </span>
                    <Select
                      options={typesOptions}
                      styles={customStyles}
                      value={selectedType}
                      isDisabled={!selectedCategory}
                      classNamePrefix='type-select'
                      components={{ IndicatorSeparator: () => null }}
                      onChange={(val) => {
                        setSelectedType(val);
                      }}
                      placeholder={
                        <FormattedMessage
                          id='documentTypes.popup.type.placeholder'
                          defaultMessage='Select type'
                        />
                      }
                    ></Select>
                  </div>
                </div>
                <div className={'document-types__btns'}>
                  <Button
                    buttonText={
                      <FormattedMessage
                        id='documentTypes.popup.btn.cancel'
                        defaultMessage='Cancel'
                      />
                    }
                    buttonType='secondary'
                    size='big'
                    onClick={() => {
                      close();
                      onClose();
                      setSelectedCategory(null);
                      setSelectedType(null);
                      setDefaultCategory(null);
                      setDefaultType(null);
                    }}
                  />
                  <Button
                    buttonText={
                      <FormattedMessage id='documentTypes.popup.btn.send' defaultMessage='Send' />
                    }
                    disabled={!selectedCategory || !selectedType}
                    size='big'
                    type='submit'
                    onClick={() => {
                      close();
                      onClose({
                        category_type: (selectedCategory || defaultCategory).value,
                        type: (selectedType || defaultType).value,
                      });
                    }}
                  />
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default DocumentTypePopup;
