import React from 'react';
import { POPUP_TYPE } from '../../../constants/popup';
import WithdrawTemplate from './WithdrawTemplate';
import Popup from 'reactjs-popup';
import TransferTemplate from './TransferTemplate/TransferTemplate';
import DepositTemplate from './DepositTemplate/DepositTemplate';

const FinancePopup = (props) => {
  const { type, onSuccess, onError, onCloseModal, trigger, ...other } = props;

  const render = (close) => {
    switch (type) {
      case POPUP_TYPE.DEPOSIT:
        return (
          <DepositTemplate
            dismissModal={close}
            onCloseModal={onCloseModal}
            onSuccess={onSuccess}
            onError={onError}
            {...other}
          />
        );
      case POPUP_TYPE.TRANSFER:
        return (
          <TransferTemplate
            dismissModal={close}
            onCloseModal={onCloseModal}
            onSuccess={onSuccess}
            onError={onError}
            {...other}
          />
        );
      case POPUP_TYPE.WITHDRAW:
        return (
          <WithdrawTemplate
            dismissModal={close}
            onCloseModal={onCloseModal}
            onSuccess={onSuccess}
            onError={onError}
            {...other}
          />
        );
      default:
        return '';
    }
  };

  return (
    <Popup modal overlayStyle={{ overflow: 'scroll' }} trigger={trigger} closeOnEscape repositionOnResize lockScroll closeOnDocumentClick>
      {(close) => render(close)}
    </Popup>
  );
};

export default FinancePopup;
