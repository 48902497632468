import React, { useEffect, useRef, useState } from 'react';
import './Deals.scss';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import Page from '../../Page/Page';
import ControlsGroup from '../../../components/ui/ControlsGroup/ControlsGroup';
import TablePagination from '../../../components/ui/TablePagination';
import { useIntl } from 'react-intl';
import DealsFilters from './DealsFilters/DealsFilters';
import DealsTable from "../../../components/ui/Table/Deals";
import { getDeals } from "../../../api/deals";

const Deals = () => {
  const [isListLoading, setIsListLoading] = useState(true);

  const [filters, setFilters] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [symbolList, setSymbolList] = useState([]);
  const componentMounted = useRef(true);
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'menu.deals',
    defaultMessage: 'Deals',
  });

  document.title = title;

  useEffect(() => {
    getTradingList(null, null);

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTradingList = (options, data) => {
    setIsListLoading(true);
    getDeals(getTablePageParams(options, data))
      .then((res) => {
        if (componentMounted.current && !res.errors) {
          setTableData(res.data.data);
          const opt = res?.data?.ticker.map((opt) => ({
            value: opt,
            label: opt,
          }));
          setSymbolList(opt);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsListLoading(false);
      })
  };

  const getTablePageParams = (options, filter) => {
    let params = ''
    if (filter) {
      if (filter && filter.trading_account) {
        params = `&trading_account=${filter.trading_account}`;
      }
      if (filter && filter.symbol) {
        params = `&symbol=${filter.symbol}`;
      }
      if (filter && filter.close) {
        params = `${params}&close[from]=${filter.close.from}&close[to]=${filter.close.to}`;
      }
      return params;
    }

    return params;
  };

  return (
    <DashboardLayout>
      <Page
        title={title}
      >
        <div className='deals__page'>
          <ControlsGroup>
            <DealsFilters
              onSubmit={(data) => {
                if (!filters && !data) {
                  return;
                }
                if (filters && !data) {
                  getTradingList({ navigate: 'first' }, null);
                  setFilters(null);
                } else {
                  setFilters(data);
                  getTradingList({ navigate: 'first' }, data);
                }
              }}
              reloadFilters={isListLoading}
              symbolList={symbolList}
            />

            <div className={'trading-table'}>
              <DealsTable
                data={tableData}
                perPage={200}
                showLoader={isListLoading}
              />              
            </div>
          </ControlsGroup>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default Deals;
