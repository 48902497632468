import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import './wallet-popup.scss';
import Button from '../../Button/Button';
import PopupTemplate from '../../PopupTemplate';
import { FormattedMessage, useIntl } from 'react-intl';
import { addDeposit, depositMethods } from '../../../../api/deposit';
import { useForm } from 'react-hook-form';
import { SELECT_DEFAULT_STYLES } from '../../../../constants/select';
import Select from '../../Select/Select';
import { Col, Container, Row } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import Preloader from '../../Preloader/Preloader';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from '../../../../utils/notify';
import './wallet.scss';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { useAppSelector } from '../../../../store';
import { getTranslations } from '../../../../utils/selectors.ts'

const cryptoWallets = {
  btc: 'Bitcoin',
  ltc: 'Litecoin',
  eth: 'Ethereum',
  dash: 'Dash',
  doge: 'Doge',
  usdt: 'Tether',
  usdc: 'USDC',
  trx: 'Tron',
};

const cryptoType = {
  btc: 'bitcoin',
  ltc: 'litecoin',
  eth: 'ethereum',
  dash: 'dash',
  doge: 'doge',
};

const methodOptions = [
  {
    value: 'Crypto',
    label: 'Crypto',
  },
  {
    value: 'credit_card',
    label: 'Credit Card',
  },
];

const WalletPopup = ({ trigger, type, onCloseModal, onSuccess, onError, paymentData }) => {
  const [isMethodLoad, setIsMethodLoad] = useState();
  const [walletList, setWalletList] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [cryptoTransaction, setCryptoTransaction] = useState();
  const [isPaymentRequestLoad, setPaymentRequestLoad] = useState(false);
  const componentMounted = useRef(true);
  const [fullWallet, setFullWallet] = useState(false);
  const translations = useAppSelector(getTranslations)
  const intl = useIntl();

  const customStyles = {
    ...SELECT_DEFAULT_STYLES,
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      maxHeight: 150,
    }),
  };

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      target_wallet: undefined,
      // method: undefined,
    },
  });

  const getMethods = () => {
    setIsMethodLoad(true);
    depositMethods()
      .then((res) => {
        if (componentMounted.current && !res.errors) {
          if (res.data.data && res.data.data['electronic'] && res.data.data['electronic'].length) {
            const wallets = res.data.data['electronic'].sort((a, b) => b.fintegrity - a.fintegrity);
            setWalletList(wallets);
            setSelectedWalletId(wallets[0].id);
            reset({ target_wallet: wallets[0].id.toString() });
          }
          setIsMethodLoad(false);
        }
      })
      .catch((err) => {
        setIsMethodLoad(false);
      });
  };

  const onSubmit = async (close, data) => {
    setPaymentRequestLoad(true);

    addDeposit({
      ...paymentData,
      method: methodOptions[0].value,
      target_wallet: data['target_wallet'],
    })
      .then((res) => {
        setPaymentRequestLoad(false);

        if (res?.data?.data) {
          const resp = res.data.data;

          if (resp.crypto_transaction && resp.crypto_transaction.wallet) {
            setCryptoTransaction({
              cryptoType: resp.crypto_transaction.wallet.crypto_type,
              wallet: resp.crypto_transaction.wallet.wallet,
              amount: resp.crypto_transaction.crypto_amount,
            });
          }
        }

        NotificationManager.success(
          intl.formatMessage({ id: 'transaction.deposit.alert.created' }), 
          intl.formatMessage({ id: 'status.success.title' }), 
          3000
        );
        onSuccess();
      })
      .catch((err) => {
        setPaymentRequestLoad(false);
        onError();
        notify({
          type: 'error',
          message: err.response,
          timeOut: 5000,
        });
      });
  };

  const closeModal = (closeModal) => {
    if (walletList && walletList.length) {
      reset({
        target_wallet: walletList[0].id.toString(),
        method: methodOptions[0],
      });
      setSelectedWalletId(walletList[0].id);
    } else {
      reset({
        target_wallet: undefined,
        method: methodOptions[0],
      });
      setSelectedWalletId(null);
    }
    setCryptoTransaction(null);
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        onOpen={getMethods}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <FormattedMessage
                id="page.finance.deposit.popup.wallet"
                defaultMessage="Crypto deposit"
              />
            }
            styles={{
              popup: {
                maxWidth: '500px',
              },
            }}
            rightContent={
              <div className={'content'}>
                {isPaymentRequestLoad ? <Preloader /> : null}
                {!cryptoTransaction && walletList?.length ? (
                  <form
                    className={`filters-form ${isPaymentRequestLoad ? 'blur' : ''}`}
                    onSubmit={handleSubmit(onSubmit.bind(undefined, close))}
                  >
                    <Container>
                      <Row>
                        <Col md={6}>
                          <div className={'content-title'}>
                            {/* <span>Payments method</span> */}
                          </div>
                          <div className={'content-method'}>
                            {/* {methodOpt.length ? (
                              <Select
                                options={methodOptions}
                                styles={customStyles}
                                control={control}
                                defaultValue={methodOptions[0]}
                                name={'method'}
                                isSearchable={false}
                                disabled={true}
                                placeholder={
                                  <FormattedMessage
                                    id="documentTypes.popup.file.category.placeholder"
                                    defaultMessage="Select category"
                                  />
                                }
                                classNamePrefix="type-select"
                                components={{ IndicatorSeparator: () => null }}
                              />
                            ) : (
                              ''
                            )} */}
                          </div>
                        </Col>
                      </Row>
                      <div className={'wallet-list'}>
                        <Row>
                          {walletList.length &&
                            walletList.map((item, index) => (
                              <Col key={item.id} md={6} sm={12}>
                                <div className={'wallet'}>
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    id={item.id}
                                    autoComplete="off"
                                    value={item.id}
                                    {...register('target_wallet')}
                                  />
                                  <label for={item.id}>
                                    <div
                                      className={`
                                        wallet__item
                                        ${selectedWalletId === item.id ? 'selected' : ''}
                                      `}
                                      onClick={() => {
                                        if (selectedWalletId !== item.id) {
                                          setSelectedWalletId(item.id);
                                        }
                                      }}
                                    >
                                      <div className={'wallet__icon'}>
                                        <i className={`${item.crypto_type?.toLowerCase()}`}></i>
                                      </div>
                                      <div className={'wallet__desc'}>
                                        <span>
                                          {cryptoWallets[item.crypto_type?.toLowerCase()]}{' '}
                                          {item.crypto_type === 'USDT' &&
                                            item.crypto_network &&
                                            `(${item.crypto_network})`}
                                        </span>
                                        <span onClick={() => setFullWallet(true)}>
                                          {!fullWallet
                                            ? item?.wallet.slice(0, 5) +
                                              '...' +
                                              item?.wallet.slice(-5)
                                            : item?.wallet}
                                        </span>
                                        <span>{item?.description}</span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </Container>
                    {/* <div className={'content-controls'}>
                      <div className={'controls__buttons'}>
                        <Button
                          buttonText={intl.formatMessage({
                            id: 'page.trading.popup.changePassword.btn.cancel',
                            defaultMessage: 'Cancel',
                          })}
                          buttonType='secondary'
                          size='big'
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <Button buttonText={'OK'} buttonType='primary' size='big' type='submit' />
                      </div>
                    </div> */}
                  </form>
                ) : (
                  ''
                )}

                {cryptoTransaction ? (
                  <div className={'transaction__info'}>
                    <div className={'transaction__qr'}>
                      <QRCode value={`${cryptoTransaction.wallet}`} size={100} />
                    </div>
                    <div className={'transaction__desc'}>
                      <span>
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text1"
                          defaultMessage="Please send"
                        />{' '}
                        <b>{cryptoTransaction.amount}</b> {cryptoTransaction.cryptoType}{' '}
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text2"
                          defaultMessage="to this wallet address"
                        />
                      </span>
                      <span
                        className={'copy_id'}
                        onClick={() => copyToBuffer(cryptoTransaction.wallet, false, intl.formatMessage({ id: 'copied.to.buffer.alert' }))}
                      >
                        {cryptoTransaction.wallet}
                      </span>
                      <span>
                        <FormattedMessage
                          id="page.finance.deposit.popup.wallet_text3"
                          defaultMessage="Funds are credited after confirmation."
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {!cryptoTransaction && !walletList?.length ? (
                  <span className={'transaction__empty'}>
                    {isMethodLoad ? 'Loading..' : 'No Wallets'}
                  </span>
                ) : (
                  ''
                )}
              </div>
            }
            footer={
              !cryptoTransaction &&
              <form
                className={`filters-form ${isPaymentRequestLoad ? 'blur' : ''}`}
                onSubmit={handleSubmit(onSubmit.bind(undefined, close))}
                style={{ paddingRight: 24, paddingBottom: 24 }}
              >
                <div className={'content-controls'}>
                  <div className={'controls__buttons'}>
                    <Button
                      buttonText={intl.formatMessage({
                        id: 'page.trading.popup.changePassword.btn.cancel',
                        defaultMessage: 'Cancel',
                      })}
                      buttonType="secondary"
                      size="big"
                      onClick={closeModal.bind(undefined, close)}
                    />
                    <Button buttonText={'OK'} buttonType="primary" size="big" type="submit" />
                  </div>
                </div>
              </form>
              
            }
          />
        )}
      </Popup>
    </>
  );
};

export default WalletPopup;
