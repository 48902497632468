import React from 'react';
import './popup-template.scss';

const PopupTemplate = ({
  dismissModal,
  headerTitle,
  leftContent,
  rightContent,
  styles,
  footer,
}) => {
  const closeModal = () => {
    dismissModal();
  };

  return (
    <div className={'wrapper popup-wrapper'} style={styles?.['wrapper']}>
      <div className={'popup'} style={styles?.['popup']}>
        <div className={'popup__title'} style={styles?.['title']}>
          <span md={12}>{headerTitle}</span>
          <i className={'popup__close-btn'} onClick={closeModal}></i>
        </div>
        <div className={'popup__content'} style={styles?.['content']}>
          {leftContent ? <div className={'popup__content-left'}>{leftContent}</div> : ''}
          <div className={`popup__content-right ${leftContent ? '' : 'full'}`}>{rightContent}</div>
        </div>
        <div className={'popup__footer'}>{footer}</div>
      </div>
    </div>
  );
};

export default PopupTemplate;
