import React, { useEffect, useState } from 'react';
import '../popup.scss';
import Amount from '../../Amount/Amount';
import Button from '../../Button/Button';
import AccountSelect from '../../AccountSelect/AccountSelect';
import { DEPOSIT_TYPE } from '../../../../constants/deposit';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { notify } from '../../../../utils/notify';
import { addDeposit } from '../../../../api/deposit';
import LoaderButton from '../../LoaderButton/LoaderButton';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormInput from '../../FormInput';
import { ArrowFatLineRight } from 'phosphor-react';
import Select from '../../Select/Select';
import './select.scss';
import LKSelect from '../../LKSelect';
import { useAppSelector } from '../../../../store';
import { getPaymentsEnableCCWithdraw } from '../../../../utils/selectors';
import { getAvailableWithdrawAmount } from '../../../../api/trading-account';

const walletOpt = [
  { value: 'btc', label: 'Bitcoin' },
  { value: 'ltc', label: 'Litecoin' },
  { value: 'eth', label: 'Ethereum' },
  { value: 'dash', label: 'Dash' },
  { value: 'doge', label: 'Doge' },
  { value: 'usdt', label: 'Tether' },
];

const WithdrawTemplate = (props) => {
  const {
    dismissModal,
    onCloseModal,
    onSuccess,
    onError,
    accountsData,
    defaultToAccount,
    ...other
  } = props;
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: '',
      crypto_type: { value: 'btc', label: 'Bitcoin' },
      wallet: '',
    },
  });
  const intl = useIntl();
  const creditCardEnabled = useAppSelector(getPaymentsEnableCCWithdraw);

  const paymentMethodsBase = [
    { value: 'Cryptocurrency', label: 'Crypto' },
    { value: 'Wire transfer', label: 'Wire' },
  ];

  const paymentMethodsWithCC = [
    ...paymentMethodsBase,
    { value: 'Credit card', label: 'credit_card_ca' },
  ];

  const paymentsMethod = creditCardEnabled ? paymentMethodsWithCC : paymentMethodsBase;

  const [account, setAccount] = useState(defaultToAccount);
  const [availableWithdraw, setAvailableWithdraw] = useState(null);
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isLoading, setLoadStatus] = useState(false);
  const [walletStep, setWalletStep] = useState('start');
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const validationText = intl.formatMessage({
    id: 'page.finance.withdraw.popup.validation',
    defaultMessage: 'Not enough funds',
  });
  const closeModal = () => {
    dismissModal();
    onCloseModal();
  };

  const onSubmit = (data) => {
    setLoadStatus(true);
    const postData = {
      amount: amount,
      amount_type: account.amount_type,
      trading_account_id: account.trading_account,
      type: DEPOSIT_TYPE.WITHDRAW,
      method: paymentMethod,
    };

    if (paymentMethod === 'Crypto') {
      if (!data['crypto_type'] || !data['wallet']) {
        NotificationManager.error(intl.formatMessage({ id: 'form.error.fillRequired' }), '', 3000);
        setLoadStatus(false);
        return;
      }
      postData.crypto_type = data['crypto_type'].value.toUpperCase();
      postData.wallet_address = data['wallet'];
    } else if (paymentMethod === 'credit_card_ca') {
      if (!data['card']) {
        NotificationManager.error(intl.formatMessage({ id: 'form.error.fillRequired' }), '', 3000);
        setLoadStatus(false);
        return;
      }
      postData.credit_card_number = data['card'];
    }

    setDisabled(true);
    addDeposit(postData)
      .then((res) => {
        NotificationManager.success(
          intl.formatMessage({ id: 'transaction.withdraw.alert.created' }),
          intl.formatMessage({ id: 'status.success.title' }),
          3000,
        );
        onSuccess();
        closeModal();
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 5000,
        });
        onError();
      })
      .finally(() => {
        setDisabled(false);
        setLoadStatus(false);
      });
  };

  const fetchAvailableWithdraw = async () => {
    if (account && account.source === 'WebTrader' && account.trading_account) {
      const response = await getAvailableWithdrawAmount(account.trading_account);
      setAvailableWithdraw(response.data);
    } else {
      setAvailableWithdraw(null);
    }
  };

  useEffect(() => {
    fetchAvailableWithdraw();
  }, [account]);

  return (
    <div className={'wrapper popup-wrapper'}>
      <div className={'popup'}>
        <div className={'popup__title'}>
          <span md={12}>
            <FormattedMessage id="page.finance.withdraw.popup.title" defaultMessage="Widthraw" />
          </span>
          <i className={'popup__close-btn'} onClick={closeModal}></i>
        </div>
        <div className={'popup__content'}>
          <div className={'popup__content-left'}>
            <div className={'popup__content-info'}>
              <i className={'icon'}></i>
              {/* <span className={'info__title'}>
                <FormattedMessage
                  id="page.finance.withdraw.popup.descr.title"
                  defaultMessage="Withdrawing from trading account"
                />
              </span> */}
              <span className={'info__description'}>
                <FormattedMessage
                  id="page.finance.withdraw.popup.descr.text"
                  defaultMessage="Request withdrawal from one of you trading accounts by selecting an account from the list, choosing a payment account and defining the desired withdrawal amount."
                />
              </span>
            </div>
          </div>
          <div className={'popup__content-right withdraw-content'}>
            <div className={'popup__content-controls'}>
              <div className={'popup__content-steps'}>
                <div
                  className={`popup__content-step step-1 ${walletStep == 'start' ? '' : 'hide'}`}
                >
                  <span className={'controls__title'}>
                    <FormattedMessage
                      id="page.finance.withdraw.popup.title"
                      defaultMessage="Withdraw request"
                    />
                  </span>
                  <LKSelect
                    options={paymentsMethod}
                    name="payment_method"
                    title={intl.formatMessage({
                      id: 'page.finance.widthdraw.popup.paymentMethod',
                      defaultMessage: 'Payment Method',
                    })}
                    control={control}
                    controlPadding="12px"
                    handleChange={(data) => {
                      setPaymentMethod(data.label);
                    }}
                    required
                  />
                  <br />
                  <AccountSelect
                    title={
                      <FormattedMessage
                        id="page.finance.withdraw.popup.field.fromTradingAccount.title"
                        defaultMessage="Choose trading account"
                      />
                    }
                    placeholder={
                      <FormattedMessage id="placeholder.select" defaultMessage="Select" />
                    }
                    accountsData={accountsData}
                    control={control}
                    name="fromWithdraw"
                    listHeight={200}
                    defaultAccount={defaultToAccount}
                    handleChange={(data) => {
                      setAccount(data.data);
                    }}
                    hideDemo
                    required
                  />
                  <div className={'controls__amount'}>
                    <Amount
                      currency={account?.amount_type}
                      error={error?.amountError}
                      disabled={!account}
                      buttons={[250, 500, 1000]}
                      onValueChange={(data) => {
                        setAmount(data);
                        if (data > availableWithdraw.available_balance_amount) {
                          setError({
                            amountError: validationText,
                          });
                        } else {
                          setError(null);
                        }
                      }}
                      details={
                        availableWithdraw &&
                        `${intl.formatMessage({
                          id: 'withdraw.available',
                          defaultMessage: 'Available',
                        })}: ${availableWithdraw.available_balance_amount}`
                      }
                      required
                    />
                  </div>
                  <span className={'controls__warning'}>
                    <FormattedMessage
                      id="page.finance.withdraw.popup.note.text"
                      defaultMessage="Note: Withdrawal request will be reviewed."
                    />
                  </span>

                  <div className={'controls__buttons'}>
                    <Button
                      buttonText={
                        <FormattedMessage
                          id="page.finance.withdraw.popup.btn.cancel"
                          defaultMessage="Cancel"
                        />
                      }
                      buttonType="secondary"
                      size="big"
                      onClick={closeModal}
                    />
                    <LoaderButton
                      buttonType="primary"
                      size="big"
                      showSpinner={isLoading}
                      disabled={!paymentMethod || !amount || !account || isLoading || error}
                      buttonText={
                        paymentMethod === 'Wire'
                          ? intl.formatMessage({
                              id: 'page.finance.withdraw.popup.btn.checkoutNow',
                              defaultMessage: 'Checkout Now',
                            })
                          : paymentMethod === 'credit_card_ca'
                          ? intl.formatMessage({
                              id: 'page.finance.withdraw.popup.btn.toCard',
                              defaultMessage: 'To Card',
                            })
                          : intl.formatMessage({
                              id: 'page.finance.withdraw.popup.btn.toWallet',
                              defaultMessage: 'To Wallet',
                            })
                      }
                      onClick={() => {
                        if (paymentMethod === 'Wire') {
                          onSubmit();
                        } else if (paymentMethod === 'Crypto') {
                          setWalletStep('crypto_select');
                        } else if (paymentMethod === 'credit_card_ca') {
                          setWalletStep('credit_select');
                        }
                      }}
                    />
                  </div>
                </div>

                <div
                  className={`popup__content-step step-2 ${walletStep !== 'start' ? '' : 'hide'}`}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {walletStep == 'crypto_select' && (
                      <span className={'controls__title'}>Enter Wallet number & currency</span>
                    )}
                    {walletStep == 'credit_select' && (
                      <span className={'controls__title'}>Enter your card</span>
                    )}
                    <div className={'controls__wallet'}>
                      <FormInput
                        name={walletStep == 'credit_select' ? 'card' : 'wallet'}
                        type="text"
                        placeholder={intl.formatMessage({
                          id:
                            walletStep == 'credit_select'
                              ? 'page.finance.withdraw.popup.deposit.card_number'
                              : 'page.finance.withdraw.popup.deposit.wallet_number',
                          defaultMessage:
                            walletStep == 'credit_select' ? 'Credit card' : 'Wallet Number',
                        })}
                        label={intl.formatMessage({
                          id:
                            walletStep == 'credit_select'
                              ? 'page.finance.withdraw.popup.deposit.card_number'
                              : 'page.finance.withdraw.popup.deposit.wallet_number',
                          defaultMessage:
                            walletStep == 'credit_select' ? 'Credit card' : 'Wallet Number',
                        })}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.requiredFieldnoName"
                              defaultMessage="Field is required"
                            />
                          ),
                        }}
                        errors={errors[walletStep == 'credit_select' ? 'card' : 'wallet']}
                        control={control}
                      />
                    </div>
                    <div className={'controls__amount-wrapp'}>
                      <div className={'controls__amount'}>
                        <span>
                          {amount} {account?.amount_type ? account.amount_type : 'USD'}{' '}
                        </span>
                        {walletStep == 'crypto_select' && <ArrowFatLineRight size={32} />}
                      </div>
                      {walletStep == 'crypto_select' && (
                        <div className={'controls__select'}>
                          <Select
                            name="crypto_type"
                            id="crypto_type"
                            classNamePrefix={'crypto-type'}
                            label={intl.formatMessage({
                              id: 'page.finance.withdraw.popup.deposit.currency',
                              defaultMessage: 'Currency',
                            })}
                            control={control}
                            rules={{
                              required: (
                                <FormattedMessage
                                  id="form.error.requiredFieldnoName"
                                  defaultMessage="Field is required"
                                />
                              ),
                            }}
                            errors={errors.crypto_type}
                            options={walletOpt}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                  <div className={'controls__buttons'}>
                    <Button
                      buttonText={'Back'}
                      buttonType="secondary"
                      size="big"
                      onClick={() => setWalletStep('start')}
                    />
                    <LoaderButton
                      buttonType="primary"
                      size="big"
                      showSpinner={isLoading}
                      // disabled={!amount || !account || isLoading || error}
                      disabled={disabled}
                      buttonText={
                        <FormattedMessage
                          id="page.finance.withdraw.popup.btn.checkoutNow"
                          defaultMessage="Checkout now"
                        />
                      }
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawTemplate;
