import React from 'react';
import Popup from 'reactjs-popup';
import './terms-popup.scss';
import Button from '../Button/Button';
import PopupTemplate from '../PopupTemplate';
import { FormattedMessage } from 'react-intl';

const TermsPopup = ({ trigger }) => {
  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={close}
            headerTitle={
              <FormattedMessage
                id="auth.signUp.popup.terms.title"
                defaultMessage="Terms and Conditions of the Client Agreement"
              />
            }
            rightContent={
              <div className={'content__terms'}>
                <div className={'content__terms-title'}>
                  <span>
                    <FormattedMessage
                      id="auth.signUp.popup.terms.contentTitle"
                      defaultMessage="Privacy Policy"
                    />
                  </span>
                </div>
                <div>
                  <div className={'text-wrapper'}>
                    <span className={'content__terms-text'}>
                      <FormattedMessage
                        id="auth.signUp.popup.terms.contentText"
                        defaultMessage="Please read the Privacy Policy carefully. This document is an integral part of the agreement between you (also named “User”) and the Company. Privacy Policy describes the rules for (the Company) work with respect to your personal data. Personal data includes any User data that idenfie s him as an individual. This Policy also explains the use of personal data that we collect when you use our services by communica ng with us by phone, e-mail or by other means. This Policy informs you what type of informa on we collect, with whom we share it and for what purposes, how we protect it and what rights you have to preserve the confiden ality of your informaon. We are serious about protecng the personal data of our customers and preserving confiden al informa on. To avoid the loss of informa on, its misuse, altera on and unauthorized access to it by third par es, we comply with all requirements, both organiza onal and technical. By accep ng the Privacy Policy, the User agrees to the collec on, storage, use and disclosure of personal data in accordance with this Privacy Policy and applicable law. You can withdraw your consent and request that you remove your personal data by contacng us."
                      />
                    </span>
                  </div>
                  <div className={'content__terms-controls'}>
                    <div className={'controls__buttons'}>
                      <Button
                        buttonText={
                          <FormattedMessage id="auth.signUp.popup.agree" defaultMessage="I agree" />
                        }
                        buttonType="primary"
                        size="big"
                        onClick={close}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default TermsPopup;
